import * as React from 'react';
import clsx from 'clsx';

import styles from './BookingWidgetSectionHeader.module.css';

interface Props {
  text: string;
}

export const BookingWidgetSectionHeader = ({ text }: Props) => {
  return (
    <div className={clsx(styles['c-title'], 'base-key__text')}>
      <h2>{text}</h2>
    </div>
  );
};

export default BookingWidgetSectionHeader;
